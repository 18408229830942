import { Box, Container, Typography } from "@mui/material";
import { Masonry } from "@mui/lab";
import { aboutData } from "../../data/aboutData";

const About = () => {
  return (
    <Container>
      <Typography variant="h1" component="h1">
        About Jake Pasternak
      </Typography>
      <Masonry columns={{ xs: 1, md: 2 }} spacing={2}>
        <Box
          component="img"
          sx={{ maxWidth: "15%", borderRadius: "5%", float: "left" }}
          alt="Jake Pasternak"
          src={`${process.env.PUBLIC_URL}/assets/images/profile.JPG`}
        />
        <Box>
          {aboutData.body &&
            aboutData.body.map((paragraph) => (
              <Typography textAlign="justify" mb={3}>
                {paragraph}
              </Typography>
            ))}
        </Box>
      </Masonry>
    </Container>
  );
};

export default About;
