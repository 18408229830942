import { Box, Container, Paper, Typography } from "@mui/material";
import { blogData } from "../data/blogData";

export const BlogPost1 = () => {
  return (
    <>
      <Box>
        <Container maxWidth={"sm"}>
          <Box mb={2}>
            <Typography variant={"h2"} component={"h2"} mb={2}>
              {blogData.title}
            </Typography>
            <Typography style={{ fontWeight: 700 }}>
              Author: {blogData.author}
            </Typography>
            <Typography style={{ fontWeight: 700 }}>
              Publish Date: {blogData.publishDate}
            </Typography>
          </Box>
          <Box>
            {blogData.body &&
              blogData.body.map((paragraph) => (
                <Typography
                  component={"p"}
                  mb={2}
                  style={{ textAlign: "justify" }}
                >
                  {paragraph}
                </Typography>
              ))}
          </Box>
        </Container>
      </Box>
    </>
  );
};
