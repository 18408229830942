export const aboutData = {
  body: [
    `Hello, my name is Jake Pasternak and I am a skilled software engineer
        with a strong background in IT infrastructure and project management.
        My interest in computers and technology started at an early age when I
        pursued computer hardware and software, networking, and basic
        scripting courses in high school. Since then, I have honed my skills
        and continued to pursue education and certification in the field.`,
    `After graduating high school, I worked at an electrical distributor
        where I quickly became the on-site desktop technician thanks to my
        natural talent for computer support. This experience sparked a greater
        interest in computer technology and led me to achieve my first
        certification: the CompTIA A+. Although I did not serve in the
        company's IT department in an official capacity, I decided to pursue
        further education in the field. In May of 2020, I received an
        Associate in Science in Computer Security and Networking Technologies
        from the University of Hawaii Honolulu Community College.`,
    `Following my education, I worked as a Project Coordinator for a large
        health insurance company in Hawaii where I became familiar with
        enterprise-level IT systems. After spending many years in
        infrastructure-related roles, I made a shift to pursue a career in
        software engineering by enrolling in a Bootcamp for coding and web
        development through UC Berkeley Extension. I now live in Tampa,
        Florida, where I am employed by JPMorgan Chase & Co. as a Software
        Engineer.`,
    `My passion for computer technology and software engineering has
        allowed me to excel in my career and continue to develop my skills. I
        am confident in my ability to tackle any project and I am always eager
        to learn more about the ever-changing landscape of technology.`,
  ],
};
