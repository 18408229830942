export const blogData = {
  title: "My Journey to Becoming a Software Engineer: A Timeline",
  body: [
    `As a child, I was always fascinated with computers. I would take apart old electronics and try to understand how they worked. I learned some Windows command line and was eager to learn more about technology. It wasn't until high school that I had the opportunity to dive deeper into the world of computers.`,

    `I took computer hardware and software classes and learned about Cisco networking and Python scripting. I was even able to serve as a student tech under the school's network admin, where I gained hands-on experience with printer repair and troubleshooting teachers' email accounts.`,

    `After high school, I worked at an electrical supply distributor in Hawaii. The company was bought out by a Californian company and the IT systems were changed. Local staff needed help with the new system and I was given local admin rights to assist them. I achieved my CompTIA A+ certification during my time there.`,

    `I realized that I wanted to pursue a career in technology, so I went to Honolulu Community College to study Computer Security and Networking Technology. During my time there, I took Java and Python classes and found that I enjoyed learning about software development and computer science. I received my Associates degree and felt confident in my abilities.`,

    `After graduation, I began working at Hawaii Medical Service Association, a local health insurance company, as an IT project coordinator as a contractor. After six months, I was offered a full-time position. While I enjoyed my work, I knew that I wanted to expand my skillset even further.`,

    `That's when I decided to take a coding and web development boot camp through UC Berkeley Extension. It was an intense program, but I learned so much and gained valuable experience working on real-world projects.`,

    `After completing the boot camp, I decided to take a chance and apply for JPMorgan Chase's Emerging Talent Software Engineer program. I was offered the opportunity to participate in a hackathon and was ultimately offered a position as a Software Engineer.`,

    `I moved to Tampa, Florida, from Hawaii to pursue this opportunity. Since then, I have been working on various projects such as web accessibility and DocuSign integrations. It has been an incredible journey to get to where I am today, but I am grateful for all of the experiences and challenges that have led me to where I am now.`,
  ],
  metadata: {
    description: `Follow my timeline from childhood fascination with computers to becoming a software engineer. Learn about my education, work experience, and how I landed my current position at JPMorgan Chase.`,
  },
  author: `Jake Pasternak`,
  publishDate: new Date("3/10/2023").toLocaleDateString(),
};
